<template>
  <BaseModal
    card-width="540px"
    no-padding
    no-exit
    no-toolbar-padding
    @close="$emit('close')"
  >
    <template #toolbar>
      <div class="flex items-start gap-3 pt-1 pb-5">
        <GptIcon />
        <div>
          <BaseText
            type="label"
            size="sm"
            class="text-text-muted"
          >
            Make this Ad
          </BaseText>
          <BaseText
            size="sm"
            class="text-text-normal"
          >
            One-click transfer Foreplay inspiration to GPT4o
          </BaseText>
        </div>
      </div>
    </template>
    <template #default>
      <div class="p-5">
        <BaseText
          type="label"
          size="sm"
          clas="text-text-muted"
        >
          Inspiration
        </BaseText>
        <div class="bg-background-disabled rounded-md py-1.5 w-full flex items-center justify-center mt-1 h-52">
          <img
            v-if="imgUrl"
            :src="imgUrl"
            class="rounded-md  h-full w-auto"
          >
          <video
            v-else
            :src="videoUrl"
            class="rounded-md h-full w-auto"
          />
        </div>
        <div class="mt-3 space-y-1" v-if="brandProfiles.length > 0">
          <BaseText
            class="text-text-muted"
            size="sm"
            type="label"
          >
            Brand
          </BaseText>
          <BaseText
            class="text-icon-normal"
            size="sm"
          >
            One-click transfer Foreplay inspiration to GPT4o
          </BaseText>
          <div class="w-full">
            <BaseSingleDropdown
              :options="brandProfiles"
              class="z-30 w-full"
              option-key="name"
              trigger-class="z-30 w-full"
              use-parent-width
              search-placeholder="Choose a brand profile"
              :selected-obj="selectedBrand"
              @change="selectedBrand = $event"
            >
              <template #icon>
                <div class="flex items-center">
                  <img :src="selectedBrand?.image || require('../../../assets/images/defaultProfileImage.webp')" alt="img" class="rounded-full w-5 h-5 border-border-normal" />
                </div>
              </template>
            </BaseSingleDropdown>
          </div>
        </div>
        <button
          class="gpt-redirect-btn mt-3 w-full px-3 py-1.5 rounded-md flex items-center justify-center gap-1"
          :disabled="loading"
          @click="createGptPrompt"
        >
          <BaseLoadingSpinnerCircle
            v-if="loading"
            class="text-text-muted"
          />
          <div
            v-else
            class="flex items-center gap-1 fade-in"
          >
            <BaseText
              class="text-white"
              type="label"
              size="sm"
            >
              Open in GPT 4o
            </BaseText>
            <ExternalLinkIcon class="text-white" />
          </div>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import { mapGetters } from 'vuex'
// Components
import BaseSingleDropdown from '../../globals/BaseSingleDropdown.vue'
// Icons
import ExternalLinkIcon from '../../globals/Icons/ExternalLinkIcon.vue'
import GptIcon from '../../globals/Icons/GptIcon.vue'
export default {
  name: 'GptAdModal',
  components: {
    BaseSingleDropdown,
    GptIcon,
    ExternalLinkIcon
  },
  props: {
    imgUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedBrand: null,
      brandProfiles: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getTeam'])
  },
  async mounted () {
    if (this.getTeam) {
      const brandProfiles = await FirebaseAPI.BrandProfiles.getAllByTeam(
        this.getTeam.id
      )
      this.brandProfiles = brandProfiles
    } else {
      const brandProfiles = await FirebaseAPI.BrandProfiles.getAllByUser()
      this.brandProfiles = brandProfiles
    }
    this.selectedBrand = this.brandProfiles?.[0]
  },
  methods: {
    async createGptPrompt () {
      this.loading = true
      try {
        const payload = {
          prompt: 'Create an ad image in this style.',
          image: this.imgUrl ?? null
        }
        const promptDoc = await FirebaseAPI.Advertisements.createGptPrompt(payload)
        const gptUrl = `https://chatgpt.com/?model=gpt-4o&fp-id=${promptDoc.id}`
        window.open(gptUrl, '_blank')
        this.$emit('close')
      } catch (e) {
        console.error(e)
        this.$showAlert({ type: 'error', message: 'Unexpected error' })
        this.$emit('close')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.gpt-redirect-btn{
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%), #121212;
  background: linear-gradient(180deg, color(display-p3 1 1 1 / 0.12) 0%, color(display-p3 1 1 1 / 0.00) 100%), color(display-p3 0.0725 0.0725 0.0725);
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #000;
  transition: background-color 300ms ease-in-out, box-shadow 300ms ease-in-out;
}
.gpt-redirect-btn:hover{
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.18) 100%), #0A0A10;
  background: linear-gradient(180deg, color(display-p3 1 1 1 / 0.18) 0%, color(display-p3 1 1 1 / 0.00) 100%), color(display-p3 0.0385 0.0411 0.0615);
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0A0A11;
}
.gpt-redirect-btn:disabled{
  background-color:#F6F8FA;
  color: #A4acb9;
  box-shadow:none;
}
</style>
